import React, { useState } from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { Link } from "gatsby";

// @material-ui/icons
import CopyIcon from "@material-ui/icons/AddCircle";

// core components
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import thisStyle from "assets/jss/material-kit-react/views/commonSections/sectionStyle.jsx";
import Muted from "components/Typography/Muted.jsx";
import Info from "components/Typography/Info.jsx";

class MoreReferencesContent extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <h2 className={classes.title}>More References</h2>
              <br />
              <div className={classes.textArea}>
                <Muted>
                  <Info><h4>Reward Calculator</h4></Info>
                  <p>https://cardano.org/calculator/</p>
                  <Info><h4>Newbie Guide - pdf</h4></Info>
                  <p>https://static.adapools.org/docs/newbie-ultimate-guide.pdf</p>
                </Muted>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

export default withStyles(thisStyle)(MoreReferencesContent);
